import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import Comfort from '../images/glc-coupe/highlights/comfort.jpeg'
import StorageSpace from '../images/glc-coupe/highlights/storage-space.jpeg'
import Sunroof from '../images/glc-coupe/highlights/sunroof.jpeg'

import HeaderImage from '../images/glc-coupe/header-glc-coupe.jpeg'

import InterieurImage from '../images/glc-coupe/gallery/interieur-teaser-glc-coupe.jpeg'
import ExterieurImage from '../images/glc-coupe/gallery/exterieur-teaser-glc-coupe.jpeg'

import InterieurImageGallery1 from '../images/glc-coupe/gallery/gallery1-interieur-glc-coupe.jpeg'
import InterieurImageGallery2 from '../images/glc-coupe/gallery/gallery2-interieur-glc-coupe.jpeg'
import InterieurImageGallery3 from '../images/glc-coupe/gallery/gallery3-interieur-glc-coupe.jpeg'

import ExterieurImageGallery1 from '../images/glc-coupe/gallery/gallery1-exterieur-glc-coupe.jpeg'
import ExterieurImageGallery2 from '../images/glc-coupe/gallery/gallery2-exterieur-glc-coupe.jpeg'
import ExterieurImageGallery3 from '../images/glc-coupe/gallery/gallery3-exterieur-glc-coupe.jpeg'
import ExterieurImageGallery4 from '../images/glc-coupe/gallery/gallery4-exterieur-glc-coupe.jpeg'
import ExterieurImageGallery5 from '../images/glc-coupe/gallery/gallery5-exterieur-glc-coupe.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
  ExterieurImageGallery5,
]

const GLCCoupe = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Auf jedem Gelände in seinem Element - das neue GLC Coupé.</H1>
      <p>
        Sein serienmäßiges Sportfahrwerk sowie seine direkte Sportlenkung,
        DYNAMIC SELECT und der permanente Allradantrieb 4MATIC mit 9G-TRONIC
        vereinen Fahrkomfort und Agilität.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>Nicht alltäglich. Aber extrem alltagstauglich.</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={Comfort}
          title="Höher sitzen, komfortabel fahren."
          headline="Höher sitzen, komfortabel fahren."
          description="Die erhabene Sitzposition im GLC Coupé verleiht ein buchstäblich höchst souveränes Fahrgefühl.
          Aus dieser Poleposition heraus können Sie die Fahrdynamik und Agilität eines Sportcoupés genießen – ein einzigartiges Erlebnis.
          Darüber hinaus bietet das Sitzkomfort-Paket eine individuelle ergonomische Anpassung der Sitzposition für Fahrer und Beifahrer – eine Wohltat insbesondere auf langen Strecken.
        Auch Fahrgäste im Fond erleben sportliches Ambiente bei komfortablem Ein- und Ausstieg: Die beiden äußeren Rücksitze sind wie Einzelsitze konstruiert und bieten den Passagieren hervorragenden Seitenhalt bei sportlicher Fahrweise. Und nach Bedarf nutzen Sie den Raum einfach für größeres Gepäck."
        />
        <ImageBox
          imagesrc={StorageSpace}
          title="Weiter blicken, Raum genießen."
          headline="Weiter blicken, Raum genießen."
          description="Das elektrische Schiebedach unterstreicht die Sportlichkeit des GLC Coupés und schafft ein großzügiges Raumgefühl.
          Für eine nahezu zugfreie Luftzirkulation im Innenraum sorgt das elektrische Schiebedach. Durch die Ausführung in Glas schafft es gleichzeitig ein helleres Ambiente. Als Sonnenschutz dient ein verschiebbares Himmelelement.
          Äußerst praktisch sind die Funktionen „Komfortschließen“ und „Sommeröffnen“, die sich über den elektronischen Zündschlüssel akivieren lassen. Bei diesen Funktionen öffnen beziehungsweise schließen sich neben dem Dach auch die Seitenscheiben. Hat sich zum Beispiel der Innenraum durch Sonneneinstrahlung erwärmt, kann das Fahrzeug komfortabel vor dem Einsteigen gelüftet werden."
        />
        <ImageBox
          imagesrc={Sunroof}
          title="Bequemer einladen, alles mitnehmen."
          headline="Bequem einladen, alles mitnehmen."
          description="Der Blick in den Laderaum des GLC Coupés weckt ein Gefühl von erstaunlicher Weite.
          Trotz der flachen Coupé-Dachlinie fällt der Laderaum mit knapp XXX Litern großzügig aus. Das GLC Coupé ist serienmäßig mit einer im Verhältnis 40/20/40 teilbaren Fondsitzlehne ausgestattet, die sich bequem elektrisch entriegeln und herunterklappen lässt und bis zu XXX Liter maximales Laderaumvolumen ermöglicht. Für besonders komfortables Be- und Entladen des Laderaums sorgt die serienmäßige EASY-PACK Heckklappe, die sich per Knopfdruck öffnen und schließen lässt. Noch einfacher wird das Beladen in Verbindung mit AIR BODY CONTROL: Über einen Schalter in der Kofferraumseitenverkleidung kann die Ladekante um vier Zentimeter abgesenkt werden.
          
          Das optionale Ablage-Paket perfektioniert mit zahlreichen zusätzlichen Ablage- und Befestigungsmöglichkeiten im Innen- und Laderaum die Variabilität und Alltagstauglichkeit des GLC Coupés."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default GLCCoupe
